<style>
.hero-video {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}
</style>

<template>
  <v-main class="text-center" id="home">
    <!-- Hero Banner -->
    <v-sheet style="position: relative; overflow: hidden;" dark>
      <video class="hero-video" playsinline autoplay muted loop :poster="require('../assets/hero_anim_static.jpg')">
        <source :src='require("../assets/video/hero_anim_vp9.webm")' type='video/webm'>
        <source :src='require("../assets/video/hero_anim_alt.mp4")' type='video/mp4'>
      </video>
      <v-sheet style="position: relative" color="rgba(0, 24, 56, 0.3)" dark>
        <v-container class="text-center" style="max-width: 1280px;" data-aos="fade">
          <v-row justify="center" align="center">
            <v-col cols="auto">
              <v-img
                :lazy-src="require('../assets/img/id_logo_simplified_lazy.png')"
                :src="require('../assets/img/id_logo_simplified.png')"
                class="justify-self-center"
                width="192px"
                aspect-ratio="1.0"
                >
              </v-img>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col class="text-center" cols="12">
              <div class="text-h3 white--text">
                Incandescent Digital
              </div>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <div class="text-h6">If you can imagine it, we can make it.</div>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="12" md="8">
              <div class="text-subtitle-1 mb-3">
                Whether you're an aspiring creator looking to broaden your horizons or a business owner ready to tackle a new project,
                we have the experience and expertise to ensure you succeed.
                Our team of experts can work with you to build a robust digital presence that is certain to increase revenue and exposure while making your life easier.
                Work with us today and let's make your visions a reality.
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-sheet>

    <!-- Portfolio -->
    <v-sheet id="portfolio" dark>
      <v-sheet style="height: 2px" color="deep-orange accent-2"></v-sheet>
      <v-container style="max-width: 1280px" data-aos="fade-up">
        <v-row justify="center">
          <v-col>
            <div class="text-h4 font-weight-light mt-3">Showcase</div>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="pa-0" style="max-width: 720px">
        <v-row justify="center">
          <v-col cols="12" md="4">
            <PortfolioCard
                :bg="require('../assets/img/portfolio/novarous_bg.png')"
                :logo="require('../assets/img/portfolio/novarous_logo.png')"
                href="https://novarous.com"
                color="blue-grey"
                name="Novarous" />
          </v-col>
          <v-col cols="12" md="4">
            <PortfolioCard
                :bg="require('../assets/img/portfolio/goblin_fight_club_bg.png')"
                :logo="require('../assets/img/portfolio/goblin_fight_club_logo.png')"
                href="https://goblinfight.club"
                color="indigo"
                name="Goblin Fight Club" />
          </v-col>
          <v-col cols="12" md="4">
            <PortfolioCard
                :bg="require('../assets/img/portfolio/tile_frenzy_bg.png')"
                :logo="require('../assets/img/portfolio/tile_frenzy_logo.webp')"
                href="https://play.google.com/store/apps/details?id=com.VektorKnight.TileFrenzy"
                color="grey"
                name="Tile Frenzy" />
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <!-- Services -->
    <v-sheet id="services" dark>
<!--      <v-sheet style="height: 2px" color="deep-orange accent-2"></v-sheet>-->
      <v-container style="max-width: 1280px" data-aos="fade-up">
        <v-row justify="center">
          <v-col>
            <div class="text-h4 font-weight-light mb-7">Services</div>
            <div class="text-subtitle-1">
              We offer a large selection of services both individually or as packaged solutions!<br>
              If you can think it, we can do it.
            </div>
          </v-col>
        </v-row>
        <v-row justify="start" align="stretch">
          <v-col cols="12">
            <!-- Desktop / Tablet Optimized -->
            <v-stepper class="hidden-sm-and-down" vertical>
              <template v-for="(service, index) in services">
                <v-stepper-step :key="`service-index-${index}`" :step="index + 1" :edit-icon="service.icon" editable>
                  {{ service.name }}
                </v-stepper-step>
                <v-stepper-content :step="index + 1" :key="`service-content-${index}`">
                  <v-card dark>
                    <v-img :src="require(`../assets/img/home/${service.image}.jpg`)" max-height="400px" aspect-ratio="1">
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                      </template>
                      <v-sheet style="height: 100%" color="rgba(0, 24, 48, 0.7)" dark>
                        <v-container fill-height>
                          <v-row justify="center" align="center">
                            <v-col cols="8">
                              <div class="text-h5 mb-4">
                                {{ service.heading }}
                              </div>
                              <template v-for="(para, index) in service.paragraphs">
                                <div class="text-body-1 mb-4" :key="index">{{ para }}</div>
                              </template>
                              <v-btn class="" color="deep-orange lighten-1" @click="$vuetify.goTo('#contact')" large>
                                Get Started
                                <v-icon right>
                                  mdi-check-decagram
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-sheet>
                    </v-img>
                  </v-card>
                </v-stepper-content>
              </template>
            </v-stepper>

            <!-- Mobile Optimized -->
            <v-expansion-panels class="hidden-md-and-up" v-model="serviceMobile" data-aos="fade-in" data-aos-duration="1000" hover light>
              <v-expansion-panel v-for="(service, index) in services" :key="index">
                <v-expansion-panel-header color="grey lighten-2">
                  <v-row justify="start" align="center" no-gutters>
                    <v-col class="mx-2" cols="auto">
                      <v-icon class="mr-4">{{ service.icon }}</v-icon>
                    </v-col>
                    <v-col cols="auto">
                      {{ service.name }}
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content color="grey lighten-3">
                 <v-row justify="center" align="center">
                  <v-col cols="auto" md="10" sm="12">
                    <div class="text-h6 mb-4">{{ service.heading }}</div>
                    <template v-for="(para, index) in service.paragraphs">
                      <div class="text-body-1" :key="index">{{ para }}</div>
                      <br v-if="index < service.paragraphs.length - 1" :key="index + service.label">
                    </template>
                  </v-col>
                 </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col>
            <div class="text-h6">
              Have something unique in mind?
            </div>
            <div class="text-subtitle-1">
              Feel free to reach out and we will be happy to work with you and find the best solution for your needs!
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-sheet style="height: 2px" color="deep-orange accent-2"></v-sheet>
    </v-sheet>

    <!-- Contact -->
    <v-parallax id="contact" class="px-0" height="unset" :src="require('../assets/img/home/contact-parallax.jpg')" light>
      <v-sheet class="py-8" style="margin: 0 -1rem" color="rgba(64, 64, 96, 0.5)">
        <v-container style="max-width: 1280px" fill-height>
          <v-row justify="center">
            <v-col cols="12">
              <ContactForm/>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-parallax>
    <v-sheet style="height: 2px" color="deep-orange accent-2"></v-sheet>
  </v-main>
</template>

<script>
import ContactForm from '../components/ContactForm.vue';
import PortfolioCard from "../components/PortfolioCard";

export default {
  name: "Home",
  components: {
    ContactForm,
    PortfolioCard
  },
  data: () => ({
    serviceMobile: null,
    services: [
        {
          name: "Website Development",
          icon: "mdi-web",
          heading: `High-Quality Websites`,
          image: "service-website",
          paragraphs: [
            `Our websites are guaranteed to be fast, user-friendly, secure, mobile-ready and prepared for the challenges of the modern web.
             We employ only the best toolsets in the industry to give you the most stable and feature-rich experience possible.`,

            `We take out the hassle by handling everything from securing your domain to the framework upon which your site will be built.`,

            `By choosing us, you can be confident that your site will stay online and grow with your aspirations.`
          ]
        },
        {
          name: "Online Stores",
          icon: "mdi-cart",
          heading: `Robust Online Stores`,
          image: "service-ecommerce",
          paragraphs: [
            `Our E-commerce packages are built around Shopify, the industry-leading E-commerce platform.
             By leveraging Shopify, we can offer integrations with the majority of print-on-demand services as well as specialized cases such as physical inventory and local delivery.`,

            `Our team of experts will work with you to build a comprehensive catalogue of merchandise to ensure your store is well-stocked and ready for your customers.`
          ]
        },
        {
          name: "Software Development",
          icon: "mdi-code-braces",
          heading: `Custom Software Solutions`,
          image: "service-software",
          paragraphs: [
            `Whether you have an idea that you want to bring to life or an existing solution needing improvement, we can make it happen.`,

            `Our engineers have experience with a large array of industry platforms and development environments.`,

            `We gaurantee our software implementations will be fast, secure, and stable.`
          ]
        },
        {
          name: "Mobile Apps & Games",
          icon: "mdi-tablet-cellphone",
          heading: `Custom Apps & Games`,
          image: "service-mobile",
          paragraphs: [
            `Whether you have an idea that you want to bring to life or an existing solution needing improvement, we can make it happen.`,
            `Our engineers have experience with most mobile development platforms including Unity and Unreal Engine for games as well as Xamarin and more for regular apps.`,
            `We take pride in our work and guarantee our mobile applications will be performant, user-friendly, and ready for any and all platforms.`

          ]
        },
        {
          name: "Graphic Design",
          icon: "mdi-palette",
          heading: `Unique Designs`,
          image: "service-design",
          paragraphs: [
            `Our experienced team of graphic designers can make art for any project. Whether it's merchandise design, web design, assets for games or software, our art is guaranteed to keep people engaged and excited for your ideas.`
          ]
        },
        {
          name: "Music Production",
          icon: "mdi-music-circle",
          heading: `Expert Music Production`,
          image: "service-music",
          paragraphs: [
            `Music is one of the most important and memorable part of any media project.
            If you are a content creator, game developer or businesses looking for that perfect score to bring your dreams to life, we have the knowledge and skill to make it happen.`,

            `If you're a musician or band looking to record, mix or master your next big hit, we can help you with that too. We will work with you to fine tune your work so it's just right for release to any streaming platform.`
          ]
        }
      ],

      clients: [
        { icon: "mdi-music-circle",     text: "We have composed soundtracks for games and content creators." },
        { icon: "mdi-album",            text: "We work with musicians to produce their work and make money from things like merchandise." },
        { icon: "mdi-code-braces",      text: "We have built applications and software for companies to increase the efficiency and effectiveness of their workflow." },
        { icon: "mdi-tablet-cellphone", text: "We have helped game developers complete, polish and market their games." },
        { icon: "mdi-cart",             text: "We have brought brick and mortar stores into the digital age by opening online stores for their products." },
        { icon: "mdi-currency-usd",     text: "We have improved the user experience for established businesses thereby increasing their conversions." },
        { icon: "mdi-tshirt-crew",      text: "Our talented team of artists have created best selling merchandise for content creators." },
        { icon: "mdi-check-bold",       text: "We take pride in preparing our clients to prosper in the online world now and in the years to come." },
      ],
  })
}
</script>
