<template>
  <v-card class="rounded-lg overflow-hidden mb-4" elevation="2" @click="onClick" style="cursor: pointer;" height="300px" data-aos="fade-up">
    <v-img :src="bg" style="object-fit: fill" height="300px" />
      <v-overlay absolute value="true" :color="color" opacity="0.7" z-index="0">
        <img :src="logo" style="width: 128px; height: 128px; margin-top: -10px">
        <div style="text-shadow: 2px 2px rgba(0, 0, 0, 0.5); width: 256px; position: absolute; margin-left: -64px">
          <h5 class="text-h5">{{name}}</h5>
        </div>
      </v-overlay>
  </v-card>
</template>

<script>
export default {
  name: "PortfolioCard",
  props: ['logo', 'bg', 'name', 'color', 'href'],
  methods: {
    onClick() {
      window.open(this.href, '_blank');
    }
  }
}
</script>

<style scoped>

</style>
