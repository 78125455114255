<template>
  <v-card style="position: relative;" color="grey darken-3" elevation="4" data-aos="fade-up" raised dark>
    <v-sheet class="pa-2" color="deep-orange lighten-1" dark>
      <div class="text-h6">
        Get In Touch
      </div>
      <div class="text-body-2">
        Fill out the form below and a member of our team will reach out to help determine which services are right for you.
      </div>
    </v-sheet>
    <v-sheet style="height: 1px" color="grey darken-4"></v-sheet>
    <v-container fluid>
      <v-form 
        ref="contactForm"
        v-model="contactValid"
        :disabled="contactState != 0" 
      >
        <v-text-field v-model="contactName" :rules="nameRules" label="Name" outlined required></v-text-field>
        <v-text-field v-model="contactEmail" :rules="emailRules" label="E-mail" outlined required></v-text-field>
        <v-textarea v-model="contactMessage" :rules="messageRules" label="Message" placeholder="Briefly describe your reason for contacting us." outlined hide-details required></v-textarea>
        <v-alert class="mt-3 mb-0 text-left" v-show="contactState == 2" type="success" dense raised elevation="1">
          Your request has been received! A team member will reach out to you shortly.
        </v-alert>
        <v-alert v-for="(error, index) in contactErrors" :key="index" class="mt-3 mb-0 text-left" type="error" dense raised elevation="1">
          {{ error }}
        </v-alert>
        <v-btn 
          class="mt-3" color="deep-orange lighten-1" 
          :disabled="!contactValid || contactState != 0" 
          :loading="contactState == 1" 
          v-on:click="submitContactForm()" 
          block 
          large
        >
          Submit
          <v-icon right>
            mdi-email
          </v-icon>
        </v-btn>
        <div class="text-caption grey--text mt-3">
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy" aria-label="Google privacy policy.">Privacy Policy</a> and
          <a href="https://policies.google.com/terms" aria-label="Google terms of service.">Terms of Service</a> apply.
        </div>
      </v-form>
    </v-container>
  </v-card>
</template>
<script>
  export default {
    name: 'ContactForm',

    data: () => ({
      // Contact form stuffs.
      recaptcha: null,
      contactValid: false,
      contactState: 0,  // 0 = Ready | 1 = Submitted/Waiting | 2 = Success
      contactErrors: [],
      contactOverlay: false,
      contactName: "",
      contactEmail: "",
      contactMessage: "",

      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 32) || 'Name must be less than 32 characters',
      ],

      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'Must be a valid E-mail address.',
      ],

      messageRules: [
        v => !!v || 'Message is required',
        v => (v && v.length <= 500) || 'Message must be less than 500 characters',
      ],
    }),
    methods: {
      submitContactForm: function () {
        //Don't submit yet
        event.preventDefault();
        
        // Secret key provided by Google
        const apiString = 'https://us-central1-id-web-vuetify.cloudfunctions.net/api/submitContact';

        // Set contact form to waiting state.
        this.contactState = 1;

        // Generate token
        this.$recaptcha('login')
          .then((token) => {
            this.$http.post(`${apiString}`, {
              token: token,
              name: this.contactName,
              email: this.contactEmail,
              message: this.contactMessage
            })
              .then((response) => {
                const success = response.data.success;

                // Take action here based on score.
                if (success) {
                  this.contactErrors = [];
                  this.contactState = 2;
                  this.contactOverlay = true;
                }
              })
              .catch((error) => {
                const errors = error.response.data.errors;
                this.contactErrors = [];
                errors.forEach((item) => {
                  if (!error.msg || item.msg == '') {
                    this.contactErrors.push("Failed reCaptcha validation. Disable any AdBlockers and try again.");
                  }
                  else {
                    this.contactErrors.push(item.msg);
                  }
                });
                this.contactState = 0;
              });
          });
      }
    },
  }
</script>