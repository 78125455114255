<template>
  <!-- Footer -->
  <v-footer id="footer" class="d-block" color="black" padless>
    <v-sheet color="grey darken-3">
      <v-btn text @click="$vuetify.goTo(0)" width="100%" dark
        >Back to Top</v-btn
      >
    </v-sheet>
    <v-sheet color="grey darken-4">
      <v-container class="text-center" style="max-width: 1280px">
        <v-row justify="center" align="center">
          <v-col cols="auto" md="6" sm="10">
            <v-btn
              v-for="(social, index) in social"
              :key="index"
              :href="social.url"
              :aria-label="social.label"
              target="_blank"
              rel="noopener"
              text
              dark
            >
              <v-icon :color="social.color" large>{{ social.icon }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="auto">
            <v-img
              :src="require('../assets/img/id_logo_full.png')"
              max-width="128px"
              fluid
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="text-caption grey--text">
              ©2020 Incandescent Digital
            </div>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="auto" md="6" sm="10">
            <v-btn
              v-for="(tech, index) in technologies"
              :key="index"
              :href="tech.url"
              :aria-label="tech.label"
              rel="noopener"
              target="_blank"
              text
              dark
            >
              <v-icon color="grey darken-2">{{ tech.icon }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-footer>
</template>

<script>
  export default {
    name: 'Footer',

    data: () => ({
      social: [
        { label: "Facebook",   icon: "mdi-facebook",   color: "blue",                 url: "https://www.facebook.com/Incandescent-Digital-319550755390736" },
        { label: "Twitter",    icon: "mdi-twitter",    color: "blue lighten-1",       url: "" },
        { label: "Instagram",  icon: "mdi-instagram",  color: "purple accent-1",      url: "https://www.instagram.com/incandescentdigital" },
        { label: "Soundcloud", icon: "mdi-soundcloud", color: "deep-orange accent-2", url: "https://soundcloud.com/incandescentdigital" }
      ],

      technologies: [
        { label: "Vue.js", icon: "mdi-vuejs", url: "https://vuejs.org/" },
        { label: "Vuetify", icon: "mdi-vuetify", url: "https://vuetifyjs.com/en/" },
        { label: "Google Firebase", icon: "mdi-firebase", url: "https://firebase.google.com/" }
      ]
    }),
  }
</script>