import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';

import axios from 'axios';
import VueAxios from 'vue-axios';
import { VueReCaptcha } from 'vue-recaptcha-v3'

import AOS from 'aos';
import 'aos/dist/aos.css';

Vue.config.productionTip = false

Vue.use(VueAxios, axios);

Vue.use(VueReCaptcha, {
  siteKey: '6Lc09dMZAAAAAGTofdgW05uJHKdQkH0vvxZIu_mX'
})

new Vue({
  router,
  vuetify,
  created () {
    AOS.init()
  },
  render: h => h(App)
}).$mount('#app')
